/*--------------------------------------------------------------
  Imports
----------------------------------------------------------------*/
@import "~react-image-gallery/styles/css/image-gallery.css";

/*--------------------------------------------------------------
  Constants
----------------------------------------------------------------*/

:root {
  --maincolor: #6236d1;
  --maincolordark: #5c23d3;
  --maincolorlight: #8377aa;
  --secondarycolor: #f9af1b;
  --secondarycolorlight: #fed073;
  --white: #ffffff;
  --dark: #000a14;
}

/*--------------------------------------------------------------
  Fonts
----------------------------------------------------------------*/

@font-face {
  font-family: "OpenSansRegular";
  src: url(./assets/fonts/Open_Sans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: url(./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: "LuckiestGuy";
  src: url(./assets/fonts/Luckiest_Guy/LuckiestGuy-Regular.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
