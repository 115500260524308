.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.primary-button {
  background: var(--secondarycolor);
  border-width: 2px;
  border-style: none;
  border-color: transparent;
  border-image: none;
  width: 14em;
  height: 3em;
  font-family: "OpenSansRegular";
  color: var(--white);
  font-size: 0.7em;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.1em;
}

.primary-button span {
  padding: 0.5em 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.primary-button span img {
  height: 20px;
  width: 20px;
  padding: 0.5em;
}

.secondary-button {
  background: var(--maincolor);
  border-width: 2px;
  border-style: none;
  border-color: transparent;
  border-image: none;
  width: 14em;
  height: 3em;
  font-family: "OpenSansRegular";
  color: var(--white);
  font-size: 0.7em;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.1em;
}

.secondary-button span {
  padding: 0.5em 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.secondary-button span img {
  height: 20px;
  width: 20px;
  padding: 0.5em;
}

.active {
  font-family: "OpenSansSemiBold" !important;
  color: var(--secondarycolor) !important;
}

.menu-item {
  cursor: pointer;
  margin: 0.5em 0;
}
