.menu-container {
  position: absolute;
}

.bm-burger-button {
  width: 30px;
  height: 30px;
  margin: 10px;
  position: fixed;
}

.bm-burger-bars {
  background: var(--secondarycolor);
}

.bm-cross {
  background: var(--secondarycolor);
}

.bm-morph-shape {
  fill: var(--maincolor);
}

.bm-item-list {
  padding: 1.8em 0 0 0.8em;
  width: 100%;
  text-align: left;
}

.bm-item {
  color: var(--white);
  text-decoration: none;
  padding: 1.5em;
  font-family: "OpenSansRegular";
}

.bm-item:focus {
  outline: none;
}

.menu-container-responsive {
  display: none;
}

/* ==========================================================================
     Media Queries - bigger than 500px
 ========================================================================== */

@media only screen and (min-width: 500px) {
  .bm-burger-button {
    display: none;
  }
  .menu-container-responsive {
    display: flex;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 100;
    justify-content: space-around;
    align-items: center;
    width: 45vw;
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 2.5em;
  }
  .menu-item {
    color: var(--white);
    text-decoration: none;
    padding: 1em;
    font-family: "OpenSansRegular";
    font-size: 1em;
  }
}
